import React, { PureComponent } from 'react';
import ProxyLambo from "../../assets/Lambo.svg";
import ProxyPeople from "../../assets/valet_people.svg";
import Building from "../../assets/building.svg";

// import Footer from '../../Components/Footer';
// import NavigationBar from "../../Components/NavBar/index";
// import GlobalStyle from "@/styles/globalStyles";

class HomePage extends PureComponent {

    render () {
        return (
            <>
                <div className='mainbody bg-purple-950 min-h-100 min-w-screen sm:w-screen'>
                    <div className='fillerline bg-purple-950 h-3 min-w-screen sm:w-screen'></div>
                    <div className='blackbg min-w-screen bg-neutral-1000 bg-opacity-30 m-4 min-h-screen grid grid-cols-6 gap-5 sm:w-screen sm:m-0 sm:mt-4 sm:flex-col sm:justify-center sm:items-center'>
                        {/* Mobile view */}
                        <div className='lambosvg col-span-6 justify-center mt-[6.25rem] mx-8 md:hidden lg:hidden xl:hidden 2xl:hidden'><img className='' src={ProxyLambo} alt="Custom Proxy Lamborghini art image" /></div>

                            <div className=' mt-[6.25rem] flex items-center col-span-6 md:hidden lg:hidden xl:hidden 2xl:hidden text-3xl text-gold-500 text-center align-self-center justify-self-center font-light'>We park your cars, <br/>so you don't have to</div>

                           <a className='mt-[6.25rem] col-span-6 md:hidden lg:hidden xl:hidden 2xl:hidden flex justify-center items-center' href=""><button className='text-gold-500 text-4xl mt-5 bg-purple-950 rounded-full py-6 px-10 shadow-[-6px_-6px_18px_#4f396f,6px_6px_18px_#000000] hover:bg-purple-900 hover:bg-opacity-50 transform hover:scale-105 transition delay-150 duration-300 ease-in-out'><span>Learn more</span></button></a>


                        {/* Plain view */}
                        <div className='container mt-[6.25rem] col-span-3 sm:hidden'>
                            <div className='2xl:text-7xl md:mt-0 mt-[5rem] text-5xl text-gold-500 text-center mb-[.938rem] font-light md:text-3xl'>We park your cars, <br/>so you don't have to</div>
                           <a href=""><button className='3xl:ml-[11rem] 2xl:text-5xl 2xl:py-12 2xl:px-40 2xl:ml-[21rem] 2xl:mt-[5rem] xl:ml-[11rem] lg:ml-[4.5rem] md:text-2xl md:py-4 md:px-16 md:ml-[3.5rem] ml-[18.2rem] text-gold-500 text-4xl mt-5 bg-purple-950 rounded-full py-6 px-20 shadow-[-6px_-6px_18px_#4f396f,6px_6px_18px_#000000] hover:bg-purple-900 hover:bg-opacity-50 transform hover:scale-105 transition delay-150 duration-300 ease-in-out'><span>Learn more</span></button></a>
                        </div>
                            <div className='lambosvg col-span-3 justify-center mt-[6.25rem] mr-8 sm:hidden'><img className='' src={ProxyLambo} alt="Custom Proxy Lamborghini art image" /></div>

                        <div className='fillerline bg-neutral-1000 bg-opacity-0 m-4 h-[7.375rem] sm:h-[3.375rem] '></div>

                    <div className='goldbg 2xl:h-[60rem] md:h-[20rem] sm:w-screen sm:mx-0 bg-gold-500 h-[30rem] col-span-6 mx-8 grid grid-cols-6 sm:h-[50rem] sm:justify-center sm:items-center'>

                        {/* Mobile view */}
                        <div className='peoplesvg col-span-6 justify-center mr-1 ml-4 md:hidden lg:hidden xl:hidden 2xl:hidden'><img className='' src={ProxyPeople} alt="Custom Proxy valet people art image" /></div>
                            {/* <div className='fillerline bg-gold-500 col-span-2 h-1'></div> */}

                            <div className='justify-self-center self-center mt-[3.25rem] col-span-6 md:hidden lg:hidden xl:hidden 2xl:hidden text-3xl text-purple-950 text-center mb-[.938rem] col-span-6'>Need flexible work? <br/>Become a Proxy Valet</div>

                            <a className='mb-[4rem] md:hidden lg:hidden xl:hidden 2xl:hidden col-span-6 flex justify-center items-center' href="/Sign-up"><button className='text-purple-950 text-4xl mt-5 bg-gold-500 rounded-full py-6 px-10 shadow-[-7px_-7px_21px_#ffef46,7px_7px_21px_#975109] font-light hover:bg-gold-600 hover:bg-opacity-30 transform hover:scale-105 transition delay-150 duration-300 ease-in-out'>Learn more</button></a>

                        {/* Plain view */}
                        <div className='peoplesvg 2xl:mt-[2rem] lg:mt-[6rem] md:mt-[3rem] col-span-3 justify-center  mr-1 ml-4 sm:hidden'><img className='' src={ProxyPeople} alt="Custom Proxy valet people art image" /></div>
                            {/* <div className='fillerline bg-gold-500 col-span-2 h-1'></div> */}
                        <div className='container mt-[6.25rem] col-span-3 mr-4 sm:hidden'>
                            <div className='2xl:text-7xl 2xl:mt-[10rem] md:text-3xl text-5xl text-purple-950 text-center mb-[.938rem] col-span-2'>Need flexible work? <br/>Become a Proxy Valet</div>
                            <a href=""><button className=' 3xl:ml-[11rem] 2xl:text-5xl 2xl:py-12 2xl:px-40 2xl:ml-[21rem] 2xl:mt-[5rem] xl:ml-[10.5rem] lg:ml-[4rem] md:text-2xl md:py-4 md:px-16 md:ml-[2.5rem] ml-[18rem] text-purple-950 text-4xl mt-5 bg-gold-500 rounded-full py-6 px-20 shadow-[-7px_-7px_21px_#ffef46,7px_7px_21px_#975109] font-light hover:bg-gold-600 hover:bg-opacity-30 transform hover:scale-105 transition delay-150 duration-300 ease-in-out'>Learn more</button></a>
                        </div>
                    </div>


                        <div className='fillerline md:hidden bg-neutral-1000 bg-opacity-0 m-4 h-[9.375rem] col-span-6 sm:hidden'></div>


                        {/* Mobile view */}
                        <div className='buildingsvg col-span-6 justify-center mt-[6.25rem] mx-8 md:hidden lg:hidden xl:hidden 2xl:hidden'><img className='' src={Building} alt="Custom Proxy valet key box art image" /></div>

                        <div className='justify-self-center self-center col-span-6 md:hidden lg:hidden xl:hidden 2xl:hidden text-3xl text-gold-500 text-center mt-[6.25rem] font-light'>Permanent needs? <br/>Partner with Proxy Business now</div>

                        <a className='md:hidden lg:hidden xl:hidden 2xl:hidden col-span-6 flex justify-center items-center' href="" onclick="window.scrollTo(0, document.getElementById('permanent-needs').offsetTop)"><button className='text-gold-500 text-4xl mt-20 bg-purple-950 rounded-full py-6 px-10 shadow-[-6px_-6px_18px_#4f396f,6px_6px_18px_#000000] hover:bg-purple-900 hover:bg-opacity-50 transform hover:scale-105 transition delay-150 duration-300 ease-in-out'>Learn more</button></a>

                        <div className='fillerline bg-neutral-1000 bg-opacity-0 m-4 h-[5.375rem] col-span-6'></div>

                        {/* Plain view */}
                        <div className='container mt-[6.25rem] col-span-3 sm:hidden'>
                            <div className='2xl:text-7xl md:text-3xl text-5xl text-gold-500 text-center mb-[.938rem] font-light'>Permanent needs? <br/>Partner with Proxy Business now</div>
                            <a href="" onclick="window.scrollTo(0, document.getElementById('permanent-needs').offsetTop)"><button className='3xl:ml-[11rem] 2xl:text-5xl 2xl:py-12 2xl:px-40 2xl:ml-[21rem] 2xl:mt-[5rem] xl:ml-[11rem] lg:ml-[4.5rem] md:text-2xl md:py-4 md:px-16 md:ml-[3.5rem] ml-[18rem] text-gold-500 text-4xl mt-5 bg-purple-950 rounded-full py-6 px-20 shadow-[-6px_-6px_18px_#4f396f,6px_6px_18px_#000000] hover:bg-purple-900 hover:bg-opacity-50 transform hover:scale-105 transition delay-150 duration-300 ease-in-out'>Learn more</button></a>
                        </div>
                        <div className='buildingsvg sm:hidden col-span-3 justify-center mt-[6.25rem] mr-8'><img className='' src={Building} alt="Custom Proxy valet key box art image" /></div>
                        <div className='fillerline sm:hidden bg-neutral-1000 bg-opacity-0 m-4 h-[9.375rem] col-span-6'></div>

                    </div>


                    <div className='linkcontainer col-span-6 grid grid-cols-3 justify-items-center items-center h-[9.375rem] 2xl:h-[19rem]'>
                       <div className='w-full h-full col-span-1 bg-gold-500 text-purple-950 hover:bg-purple-950 hover:text-gold-500 text-center flex items-center justify-center'> <button className='2xl:text-6xl text-4xl sm:text-2xl'><a href="/Sign-up">Valet with <br /> Proxy</a></button></div>
                       <div className='w-full h-full col-span-1 bg-gold-500 text-purple-950 hover:bg-purple-950 hover:text-gold-500 text-center flex items-center justify-center'> <button className='2xl:text-6xl text-4xl sm:text-2xl'><a href="">Work with <br /> Proxy</a></button></div>
                       <div className='w-full h-full col-span-1 bg-gold-500 text-purple-950 hover:bg-purple-950 hover:text-gold-500 text-center flex items-center justify-center'> <button className='2xl:text-6xl text-4xl sm:text-2xl'><a href="">Book an event <br /> with Proxy</a></button></div>
                    </div>
                </div>
            </>
        );
    }
}

export default HomePage;
