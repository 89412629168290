import React from "react";


class Careers extends React.Component {

    render() {

        return (
            <>
            </>
        )
    }
}

export default Careers;
