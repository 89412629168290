import React from "react";
import { Link , BrowserRouter as Router} from 'react-router-dom';



class NavBarLinks extends React.Component{


    render() {

        return(
            <>
                <div>
                    <nav className="flex justify-end xl:text-2xl items-center text-gold-500 space-x-16 lg:space-x-10 xl:space-x-8 2xl:space-x-15 mr-[1rem] sm:hidden md:hidden m-0 p-0">
                       <div className="2xl:text-4xl xl:text-2xl text-md hover:text-purple-300 hover:decoration-purple-300"> <Link to={"/"} >Home</Link></div>
                       <div className="2xl:text-4xl xl:text-2xl text-md hover:text-purple-300 hover:decoration-purple-300"> <Link onClick={this.scrollToBottom} >About</Link></div>
                       <div className="2xl:text-4xl xl:text-2xl text-md hover:text-purple-300 hover:decoration-purple-300"> <Link onClick={this.scrollToBottom}>Contact</Link></div>
                       <div className="2xl:text-4xl xl:text-2xl text-md hover:text-purple-300 hover:decoration-purple-300"> <Link to={""} >Event-Booking</Link></div>
                       <div className="2xl:text-4xl xl:text-2xl text-md hover:text-purple-300 hover:decoration-purple-300"> <Link to={""} >Services</Link></div>
                       <div className="2xl:text-4xl xl:text-2xl text-md hover:text-purple-300 hover:decoration-purple-300"> <Link to={"/sign-up"}>Sign up/Sign in</Link></div>
                       <div className="2xl:text-4xl xl:text-2xl text-md hover:text-purple-300 hover:decoration-purple-300"> <Link to={""}>Careers</Link></div>
                    </nav>
                </div>
            </>
        )
    }
    scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };
}
export default NavBarLinks;
