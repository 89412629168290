import React, { PureComponent } from 'react';
import CarVector from "../../assets/Car_vector.svg";
import ProxyLogo from "../../assets/proxy_and_car.svg";
import { Link } from 'react-router-dom';
// import GlobalStyle from "@/styles/globalStyles";

class NotFoundPage extends PureComponent {

    render() {
        return (
            <body className="h-screen bg-purple-950 max-w-full overflow-hidden">
                <img src={ProxyLogo} alt="Proxy Logo" className="logo h-24 w-auto absolute right-10 top-10 md:left-10 sm:left-10 sm:h-12" ></img>
                <div className="animate-fadein">
                    <img src={CarVector} alt="Car" className=" car1 h-[18rem] absolute left-[25rem] top-[.625rem] rotate-45 sm:left-[4.5rem] sm:top-[10rem] sm:h-[9rem]"></img>
                </div>
                <div className="h-screen flex justify-center items-center">
                    <ul>
                        <div className="text-gold-500 text-8xl text-center sm:text-4xl">404</div>
                        <p className="text-gold-500 text-4xl text-center sm:text-2xl">Uh oh! You're lost!<br></br>
                            Click here to return home.</p>
                        <Link to={"/"} >
                            <button type="button" className="text-center bg-gold-500 text-purple-950 text-7xl
                            h-[150px] w-[420px]
                            rounded-tl-[10%] rounded-tr-[10%] rounded-br-[40%] rounded-bl-[40%]
                        shadow-lg shadow-neutral-1000
                         hover:bg-purple-700 hover:text-gold-500 sm:text-3xl sm:h-[75px] sm:w-[210px] sm:ml-[2rem]" >Home</button>
                        </Link>
                    </ul>
                </div>
                <div className="animate-fadeinOther">
                    <img src={CarVector} alt="Car" className="car2 h-[18rem] absolute right-[25rem] bottom-[.625rem] rotate-[-135deg] sm:h-[9rem] sm:right-[4.5rem] sm:bottom-[8rem]"></img>
                </div>
            </body>
        );
    }
}

export default NotFoundPage;
