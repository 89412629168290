import React from "react";
import { BrowserRouter as Router, Routes, Route, Outlet} from "react-router-dom";

import HomePage from './pages/HomePage/HomePage';
import ComingSoonPage from './pages/ComingSoonPage/ComingSoonPage';
import NotFound from './pages/NotFoundPage/NotFoundPage';
import AboutPage from './pages/AboutPage/AboutPage';
import EventPage from "./pages/EventPage/EventPage";
import ServicePage from "./pages/ServicePage/ServicePage";
import NavigationBar from "./Components/NavBar/NavigationBar";
import Footer from "./Components/Footer/Footer";
import SignUp from "./pages/Sign-up";
import Careers from "./pages/Careers";




function LayoutsWithNavbar() {
    return (
      <>
        <NavigationBar />
        <Outlet />
        <Footer />
      </>
    );
  }



function App() {
    return (

        <Router>
            {/* <NavigationBar /> */}
            <Routes>
                    {/* <Route exact path="/" element={<ComingSoonPage />} /> */}
                <Route path="/" element={<LayoutsWithNavbar />}>
                    <Route path="/" element={<HomePage />} />
                    {/* <Route path="/About" element={<AboutPage />} /> */}
                    {/* <Route path="/Events" element={<EventPage />} /> */}
                    {/* <Route path="/Services" element={<ServicePage />} /> */}
                    <Route path="/Sign-up" element={<SignUp />} />
                    {/* <Route path="/Careers" element={<Careers />} /> */}
                </Route>
                    <Route exact path="*" element={<NotFound />} />
            </Routes>
            {/* <Footer /> */}
        </Router>

    );
}

export default App;


