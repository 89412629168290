import ProxyLogo from "../../assets/Proxy_logo.png";
import NavBarLinks from "../NavBarLinks/NavBarLinks.jsx";
import { GiHamburgerMenu } from "react-icons/gi";
import React, {useState} from "react";
import { Link , BrowserRouter as Router} from 'react-router-dom';



const NavigationBar = () => {
    let [scrollToBottom, setScrollToBottom] = useState(false);
    const [isBurgerLinksOpen, setIsBurgerLinksOpen] = useState(false);

    scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };

    const toggleBurgerLinks = () => {
      setIsBurgerLinksOpen(!isBurgerLinksOpen);
    };

        return (
            <>
            <div>
                 <div className="bg-purple-950 h-20 m-0 p-0">
                    <div className="flex gap-4 place-content-between h-20 items-end ">
                       <img src={ProxyLogo} alt="Proxy Logo" className="h-[65px] w-auto m-1 ml-3 sm:h-[45px]"></img>
                     <NavBarLinks/>

                        <GiHamburgerMenu onClick={toggleBurgerLinks} className="burger block h-10 w-10 mb-5 mr-3 fill-gold-500 lg:hidden xl:hidden 2xl:hidden"/>

                    </div>
                </div>
                    <div className='bg-gold-500 h-1.5'></div>
            </div>
                    <div className={`burgerLinks h-[24rem] bg-lilac-700 min-width-100 grid-cols-6 text-center items-center ${
          isBurgerLinksOpen ? "block" : "hidden"}`}>
                        <div className="text-3xl text-gold-200 col-span-1 h-[4rem] pt-[1rem] hover:bg-gold-500"> <Link to={"/home"} >Home</Link></div>
                        <div className="text-3xl text-gold-200 col-span-1 h-[4rem] pt-[1rem] hover:bg-gold-500"> <Link onClick={scrollToBottom} >About/Contact</Link></div>
                        <div className="text-3xl text-gold-200 col-span-1 h-[4rem] pt-[1rem] hover:bg-gold-500"> <Link to={"/Events"} >Event-Booking</Link></div>
                        <div className="text-3xl text-gold-200 col-span-1 h-[4rem] pt-[1rem] hover:bg-gold-500"> <Link to={"/Services"} >Services</Link></div>
                        <div className="text-3xl text-gold-200 col-span-1 h-[4rem] pt-[1rem] hover:bg-gold-500"> <Link to={"/sign-up"}>Sign up/Sign in</Link></div>
                        <div className="text-3xl text-gold-200 col-span-1 h-[4rem] pt-[1rem] hover:bg-gold-500"> <Link to={"/careers"}>Careers</Link></div>
                    </div>
            </>
        )
}

export default NavigationBar;
