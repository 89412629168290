import React, { useState } from 'react';
import Calendar from "../../assets/calendar.svg";
import Money from "../../assets/money.svg";
import Geopin from "../../assets/geopin.svg";



const SignUp = () => {

//     const [isFlipped1, setIsFlipped1] = useState(false);
//     const [isFlipped2, setIsFlipped2] = useState(false);
//     const [isFlipped3, setIsFlipped3] = useState(false);

//   const handleFlipOne = () => {
//     setIsFlipped1(!isFlipped1);
//   };

//   const handleFlipTwo = () => {
//     setIsFlipped2(!isFlipped2);
//   };

//   const handleFlipThree = () => {
//     setIsFlipped3(!isFlipped3);
//   };


        return(
            <>
             <div className='bg-purple-950 min-h-100 grid grid-cols-6 p-0 m-0'>

                <div className='col-span-6 2xl:h-[10rem] h-[5rem] bg-gold-500 flex justify-center items-center mt-[2rem] md:mx-0 mx-[4rem] sm:mx-0'><p className='lg:text-4xl md:text-4xl sm:text-2xl text-7xl text-purple-950'>Park With Ease, Valet Please!</p></div>
                <div className='col-span-6 h-1.5 bg-gold-500 md:mx-0 mx-[4.8rem] mt-[3rem] sm:mx-0'></div>
                <div className='col-span-6 flex justify-center items-center mt-[4.5rem] mb-[4.5rem]'><p className='text-5xl text-gold-500'>Why you should become a Proxy Valet</p></div>

                <div className='blackbg md:mx-0 sm:h-[100rem] sm:mx-0 col-span-6 bg-neutral-1000 bg-opacity-30 mx-6 h-[30rem] grid grid-cols-3 gap-2.5'>

                    <div className='first 2xl:mx-[5rem] sm:col-span-3 sm:mx-5 col-span-1 my-2.5 ml-2.5 bg-purple-500 bg-opacity-50 grid grid-rows-3 grid-cols-2 justify-center items-center relative transition-all duration-1000 [transform-style:preserve-3d] hover:[transform:rotateY(180deg)]'>
                        <div className='md:h-[13rem] md:w-[13rem] sm:mt-8 [backface-visibility:hidden] col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem]'><img className='md:h-[9rem] h-[12rem]' src={Geopin} alt="Proxy Geopin custom art image" /></div>
                        <div className='[backface-visibility:hidden] mt-[2rem] col-span-2 self-start justify-self-center row-span-1'><p className='md:text-3xl text-gold-500 text-4xl'>Just show up</p></div>
                        <div className='2xl:text-3xl absolute h-full w-full text-gold-500 text-center mt-[1rem] [transform:rotateY(180deg)] [backface-visibility:hidden]'>
                           <ul className='flex flex-col justify-center ml-10 pt-[4rem]'>
                             <li className='mt-7 3xl:mt-2.5 3xl:mr-8'>• Be notified about all events in your area</li>
                             <li className='mt-7 3xl:mt-2.5 3xl:mr-8'>• Work events that are convenient for you</li>
                             <li className='mt-7 3xl:mt-2.5 3xl:mr-8'>• Sign up for the events you're interested in</li>
                             <li className='mt-7 3xl:mt-2.5 3xl:mr-8'>• Show up and work the event, make money</li>
                           </ul>
                        </div>
                    </div>

                    <div className='second 2xl:mx-[5rem] sm:col-span-3 sm:mx-5 col-span-1 my-2.5 bg-purple-500 bg-opacity-50 grid grid-rows-3 grid-cols-2 justify-center items-center relative transition-all duration-1000 [transform-style:preserve-3d] hover:[transform:rotateY(180deg)]'>
                        <div className='md:h-[13rem] md:w-[13rem] sm:mt-8 [backface-visibility:hidden] col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem]'><img className='md:h-[5rem] h-[7rem]' src={Money} alt="Proxy Money custom art image" /></div>
                        <div className='[backface-visibility:hidden] mt-[2rem] col-span-2 self-start justify-self-center row-span-1'><p className='md:text-3xl text-gold-500 text-4xl'>Earn more</p></div>
                        <div className='2xl:text-3xl absolute h-full w-full text-gold-500 text-center mt-[1rem] [transform:rotateY(180deg)] [backface-visibility:hidden]'>
                           <ul className='flex flex-col justify-center items-start ml-10 pt-[4rem]'>
                            <li className='mt-7 3xl:mt-2.5 3xl:mr-7'>• Competitive base pay of $15/hour</li>
                            <li className='mt-7 3xl:mt-2.5 3xl:mr-7'>• You keep 100% of all tips you receive</li>
                            <li className='mt-7 3xl:mt-2.5 3xl:mr-7'>• Unlimited earning potential</li>
                            <li className='mt-7 3xl:mt-2.5 3xl:mr-7'>• Perfect part-time opportunity</li>
                           </ul>
                        </div>
                    </div>

                    <div className='third 2xl:mx-[5rem] sm:col-span-3 sm:mx-5 col-span-1 my-2.5 mr-2.5 bg-purple-500 bg-opacity-50 grid grid-rows-3 grid-cols-2 justify-center items-center relative transition-all duration-1000 [transform-style:preserve-3d] hover:[transform:rotateY(180deg)]'>
                        <div className='md:h-[13rem] md:w-[13rem] sm:mt-8 [backface-visibility:hidden] col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem]'>
                            <img className='md:h-[9rem] h-[10.5rem]' src={Calendar} alt="Proxy Calendar custom art image" />
                        </div>
                        <div className='[backface-visibility:hidden] mt-[2rem] col-span-2 self-start justify-self-center row-span-1'><p className='md:text-3xl text-gold-500 text-4xl'>Flexibility</p></div>
                        <div className='2xl:text-3xl absolute h-full w-full text-gold-500 text-center mt-[1rem] [transform:rotateY(180deg)] [backface-visibility:hidden]'>
                        <ul className='flex flex-col justify-center items-start ml-10 pt-[4rem]'>
                            <li className='mt-7 3xl:mt-2.5 3xl:mr-6'>• You choose the events you want to work</li>
                            <li className='mt-7 3xl:mt-2.5 3xl:mr-6'>• No long-term commitments</li>
                            <li className='mt-7 3xl:mt-2.5 3xl:mr-6'>• Easily achieve work-life balance</li>
                            <li className='mt-7 3xl:mt-2.5 3xl:mr-6'>• Start immediately after onboarding</li>
                           </ul>
                        </div>
                    </div>

                </div>

                <div className='mx-1 mt-[3rem] h-[11rem] col-start-3 col-span-2 justify-self-center'>
                    <button className='sm:py-8 sm:px-20 text-gold-500 text-4xl bg-purple-950 rounded-full py-9 px-36 shadow-[-6px_-6px_18px_#4f396f,6px_6px_18px_#000000] hover:bg-purple-900 hover:bg-opacity-50 transform hover:scale-105 transition delay-150 duration-300 ease-in-out'><a href="https://drive.proxyvalet.com/driver-registration">Register</a></button>
                </div>
            </div>
            </>
        )
    }


export default SignUp;





                    // <div className='col-span-1 my-5 ml-5 bg-purple-500 bg-opacity-50 grid grid-rows-3 grid-cols-2 justify-center items-center relative transition-all duration-1000 [transform-style:preserve-3d] hover:[transform:rotateY(180deg)]'>
                    //     <div className='[backface-visibility:hidden] col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem]'>
                    //         <img className='h-[10.5rem]' src={Calendar} alt="Proxy Calendar custom art image" />
                    //     </div>
                    //     <div className='[backface-visibility:hidden] mt-[2rem] col-span-2 self-start justify-self-center row-span-1'><p className='text-gold-500 text-4xl'>Flexibility</p></div>
                    //     <div className='absolute h-full w-full text-gold-500 text-center mt-[1rem] [transform:rotateY(180deg)] [backface-visibility:hidden]'>
                    //        <p> Is my flip effect working yet?!</p>
                    //     </div>
                    // </div>

                    // <div className='col-span-1 my-5 bg-purple-500 bg-opacity-50 grid grid-rows-3 grid-cols-2 justify-center items-center relative transition-all duration-1000 [transform-style:preserve-3d] hover:[transform:rotateY(180deg)]'>
                    //     <div className='[backface-visibility:hidden] col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem]'><img className='h-[12rem]' src={Geopin} alt="Proxy Geopin custom art image" /></div>
                    //     <div className='[backface-visibility:hidden] mt-[2rem] col-span-2 self-start justify-self-center row-span-1'><p className='text-gold-500 text-4xl'>Just show up</p></div>
                    //     <div className='absolute h-full w-full text-gold-500 text-center mt-[1rem] [transform:rotateY(180deg)] [backface-visibility:hidden]'>
                    //        <p> Is my flip effect working yet?!</p>
                    //     </div>
                    // </div>

                    // <div className='col-span-1 my-5 mr-5 bg-purple-500 bg-opacity-50 grid grid-rows-3 grid-cols-2 justify-center items-center relative transition-all duration-1000 [transform-style:preserve-3d] hover:[transform:rotateY(180deg)]'>
                    //     <div className='[backface-visibility:hidden] col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem]'><img className='h-[7rem]' src={Money} alt="Proxy Money custom art image" /></div>
                    //     <div className='[backface-visibility:hidden] mt-[2rem] col-span-2 self-start justify-self-center row-span-1'><p className='text-gold-500 text-4xl'>Earn more</p></div>
                    //     <div className='absolute h-full w-full text-gold-500 text-center mt-[1rem] [transform:rotateY(180deg)] [backface-visibility:hidden]'>
                    //        <p> Is my flip effect working yet?!</p>
                    //     </div>
                    // </div>


                //     <div id={'card' + (isFlipped1 ? "flipped" : "")} className={`col-span-1 my-5 ml-5 bg-purple-500 bg-opacity-50 grid grid-rows-3 grid-cols-2 justify-center items-center relative ${isFlipped1 ? 'transform rotate-y-180 [transform-style:preserve-3d] transition-all duration-500' : ''}`} onMouseEnter={handleFlipOne} onMouseLeave={handleFlipOne}>
                //         <div className={`col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem] ${isFlipped1 ? 'hidden' : ''}`}>
                //             <img className='h-[12rem]' src={Geopin} alt="Proxy Calendar custom art image" />
                //         </div>
                //         <div className={`mt-[2rem] col-span-2 self-start justify-self-center row-span-1 ${isFlipped1 ? 'hidden' : ''}`}>
                //             <p className='text-gold-500 text-4xl'>Just show up</p>
                //         </div>
                //     <div className={`col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem] ${isFlipped1 ? '' : 'hidden'}`}>
                //         <div className='flex justify-center items-center'>
                //             <p className='text-gold-500 text-4xl text-center'>Flip effect coming soon</p>
                //         </div>
                //     </div>
                //     </div>

                //     <div id={'card' + (isFlipped2 ? "flipped" : "")} className={`col-span-1 my-5 bg-purple-500 bg-opacity-50 grid grid-rows-3 grid-cols-2 justify-center items-center ${isFlipped2 ? 'transform rotate-y-180' : ''}`} onMouseEnter={handleFlipTwo} onMouseLeave={handleFlipTwo}>
                //         <div className={`front col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem] ${isFlipped2 ? 'hidden' : ''}`}>
                //             <img className='h-[7rem]' src={Money} alt="Proxy Calendar custom art image" />
                //         </div>
                //         <div className={`front mt-[2rem] col-span-2 self-start justify-self-center row-span-1 ${isFlipped2 ? 'hidden' : ''}`}>
                //             <p className='text-gold-500 text-4xl'>Earn more</p>
                //         </div>
                //     <div className={`back col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem] ${isFlipped2 ? '' : 'hidden'}`}>
                //         <div className='flex justify-center items-center'>
                //             <p className='text-gold-500 text-4xl text-center'>Flip effect coming soon</p>
                //         </div>
                //     </div>
                //     </div>

                //     <div id={'card' + (isFlipped3 ? "flipped" : "")} className={`card + {}}col-span-1 my-5 mr-5 bg-purple-500 bg-opacity-50 grid grid-rows-3 grid-cols-2 justify-center items-center ${isFlipped3 ? 'transform rotate-y-180 transform-style-3d' : ''}`} onMouseEnter={handleFlipThree} onMouseLeave={handleFlipThree}>
                //         <div className={`col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem] ${isFlipped3 ? 'hidden' : ''}`}>
                //             <img className='h-[10.5rem]' src={Calendar} alt="Proxy Calendar custom art image" />
                //         </div>
                //         <div className={`mt-[2rem] col-span-2 self-start justify-self-center row-span-1 ${isFlipped3 ? 'hidden' : ''}`}>
                //             <p className='text-gold-500 text-4xl'>Flexibility</p>
                //         </div>
                //     <div className={`col-span-2 self-end justify-self-center row-span-2 bg-purple-950 bg-opacity-50 rounded-full flex justify-center items-center h-[16rem] w-[16rem] ${isFlipped3 ? '' : 'hidden'}`}>
                //         <div className='flex justify-center items-center'>
                //             <p className='text-gold-500 text-4xl text-center'>Flip effect coming soon</p>
                //         </div>
                //     </div>
                // </div>
