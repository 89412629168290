import React, {useState} from "react";




const Footer = () => {

    const [isLoadMore, setIsLoadMore] = useState(false);

    const toggleLoadMore = () => {
        setIsLoadMore(!isLoadMore);
      };

        return (
            <>
            <div>
                        <div className={`sm:text-2xl sm:px-10 2xl:text-5xl 2xl:pr-[30rem] 2xl:pl-[25rem] 3xl:text-2xl overlay border-gold-500 border-2 fade fixed top-0 left-0 w-full h-full overflow-x-hidden overflow-y-auto bg-purple-950 min-width-100 text-left justify-start text-gold-500 px-20 text-3xl pb-20 ${
          isLoadMore ? "block" : "hidden"}`}>
            <button type="button" className="sm:h-[2rem] sm:w-[2rem] sm:text-2xl 2xl:right-[25rem] 2xl:top-[10rem] 2xl:h-[3rem] 2xl:w-[3rem] 3xl:top-[6rem] 3xl:right-[15rem] 3xl:h-[2rem] 3xl:w-[2rem] 3xl:text-xl border-gold-500 border-2 bg-lilac-400 hover-cursor-pointer text-4xl absolute top-8 right-8" onClick={toggleLoadMore}>X</button>
                        <p> <br /> <br /> <br /><br /> About Us <br /><br /> Proxy Valet is a valet parking company that offers both gig and full-time valet services. Our gig drivers can choose their own schedule and sign up for events that are available. We also offer full-time valet services for businesses that require that level of service.
We believe that valet parking should be convenient and affordable for everyone. That's why we offer a variety of pricing options to fit your needs. We also have a team of experienced and professional valet drivers who are dedicated to providing you with the best possible service.
Whether you're hosting a large event or need valets to fulfill permanent needs, Proxy Valet is the perfect solution. We'll take care of your client's vehicles so they can relax and enjoy your event/services. <br /> <br /> Here are some of the benefits of using Proxy Valet: <br /> <br />

• Convenient: We'll come to you and set up what we need. Just schedule your event and we'll take it from there!   <br /><br />
• Affordable: We offer a variety of pricing options to fit your needs. <br /><br />
• Professional: Our team of valet drivers are experienced and professional. <br /><br />
• Reliable: We're always on time and we'll take good care of your client's vehicles. <br /> <br />

Contact us today to learn more about our services!</p>
                    </div>
                    <div className='fillerline bg-gold-500 h-1.5 m-0 p-0'></div>
                    <div className='footercontainer sm:h-[60rem] h-[25rem] bg-purple-950 h-[18.75rem] col-span-6 grid grid-cols-3 justify-items-center items-top sm:items-center'>
                        <div className='md:text-sm sm:col-span-3 col-span-1 text-xl text-center text-gold-500 mt-[2rem]'><p><strong>Contact</strong>  <br /><br />(561)-951-5477 <br /><br /><a className='underline hover:text-purple-300 hover:decoration-purple-300' href="mailto:support@proxyvalet.com">support@proxyvalet.com</a><br /><br />40946 US Hwy 19 N, #132<br /> Tarpon Springs, FL 34689</p></div>

                        <div className={`md:text-sm sm:col-span-3 col-span-1 text-xl text-center text-gold-500 mt-[2rem] ${ isLoadMore ? "hidden" : "block"}`}><p><strong>About</strong> <br /> <br />Proxy Valet is a valet parking company that offers both gig and full-time valet services. Our gig drivers can choose their own schedule and sign up for events that are available. We also offer full-time valet services for businesses that require that level of service.
We believe that valet parking should be convenient and affordable for everyone. That's why we offer a variety of pricing options to fit your needs.  <a className="underline hover:cursor-pointer" onClick={toggleLoadMore}>Load More...</a>
                    </p></div>
                        <div className='md:text-sm sm:col-span-3 col-span-1 text-xl text-center text-gold-500 mt-[2rem]'><p><a className='underline hover:text-purple-300 hover:decoration-purple-300' href="">Privacy Policy <br /><br /><br /></a><a className='underline hover:text-purple-300 hover:decoration-purple-300' href="">Terms</a><br /><br /><br /><a className='underline hover:text-purple-300 hover:decoration-purple-300' href="">Accessibility</a></p></div>
                    </div>
                    <div className='trademark md:col-span-3 sm:col-span-3 text-lilac-500 flex justify-center col-span-6 bg-purple-950'><p>™Proxy Valet Corp. 2023</p></div>
            </div>
            </>
        )
}

export default Footer;
